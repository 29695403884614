var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[(_vm.canEdit)?_c('v-btn',{staticClass:"common-botton",attrs:{"rounded":"","dark":""},on:{"click":function($event){return _vm.$emit('changeComponent')}}},[_c('span',{staticStyle:{"padding-right":"11%"}},[_vm._v(" Editar ")]),_c('v-icon',{attrs:{"rigth":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Teléfono ")]),_c('br'),_c('span',{staticClass:"text-data-details"},[_vm._v(" "+_vm._s(_vm.clienteInfo.telefonoOficina ? _vm.clienteInfo.telefonoOficina : "Sin llenar")+" ")])]),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Correo ")]),_c('br'),_c('span',{staticClass:"text-data-details"},[_vm._v(" "+_vm._s(_vm.clienteInfo.correo ? _vm.clienteInfo.correo : "Sin llenar")+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.clienteInfo.phones),function(telefono,idx){return _c('v-row',{key:idx},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Teléfonos contacto "+_vm._s(idx + 1)+" ")]),_c('br'),_c('span',{staticClass:"text-data-details"},[_vm._v(" "+_vm._s(telefono.telefono ? telefono.telefono : "Sin llenar")+" ")])])],1)}),1),_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.clienteInfo.correosContacto),function(correo,idx){return _c('v-row',{key:idx},[_c('v-col',{class:correo.favorito
              ? 'whitout-padding-bottom favorite-color'
              : 'whitout-padding-bottom',attrs:{"cols":"2","m":"2","md":"2","lg":"1","xl":"1"}},[_c('span',[_c('h3',[_vm._v(_vm._s(idx + 1)+"°")])])]),_c('v-col',{class:correo.favorito
              ? 'whitout-padding-top favorite-color'
              : 'whitout-padding-top',attrs:{"cols":"5","m":"5","md":"5","lg":"4","xl":"4"}},[_c('v-row',[_c('v-col',{staticClass:"whitout-padding-bottom",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Correo electrónico ")])]),_c('v-col',{staticClass:"whitout-padding-top",attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(correo.correo)+" ")])])],1)],1),_c('v-col',{class:correo.favorito
              ? 'whitout-padding-top whitout-padding-bottom favorite-color'
              : 'whitout-padding-top whitout-padding-bottom',attrs:{"cols":"5","m":"5","md":"5","lg":"2","xl":"2"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":correo.favorito ? '#00a7e4' : '#ABB1B3'}},[_vm._v(" "+_vm._s(correo.favorito ? "mdi-heart" : "mdi-heart-outline")+" ")])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }